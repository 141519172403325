import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { api_Url } from "../../App";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Blocks } from "react-loader-spinner";

export default function MainSlider() {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    swipe: true,
    fade: true,
    swipeToSlide: true,
    slidesToShow: 1,
    autoplay: true,
    draggable: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    slidesToScroll: 1,
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${api_Url}/frontend/sliders`);
        setItems(response?.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <div
          className="w-100 min-vh-100 position-fixed top-0 bottom-0 start-0 end-0 bg-body-tertiary d-flex align-items-center justify-content-center"
          style={{ zIndex: 100000 }}
        >
          <Blocks
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            visible={true}
          />
        </div>
      ) : (
        <section
          className="main-slider max-vh-100 position-relative bottom-0"
          id="slider"
        >
          <div className="gallery">
            <Slider {...settings}>
              {items.map((item) => {
                return (
                  <div className="image" key={item.id}>
                    {i18n.language === "en" ? (
                      <img src={item.image_en} className="w-100 h-100" alt="" />
                    ) : (
                      <img src={item.image_ar} className="w-100 h-100" alt="" />
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
      )}
    </>
  );
}
