import React from "react";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import { api_Url } from "../../App";
import { Translation, useTranslation } from "react-i18next";

export default function Accordions() {
  const [t, i18n] = useTranslation();
  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api_Url}/frontend/faqs`);
        setItems(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Accordion defaultActiveKey="1">
        {items?.map((item, index) => {
          return (
            <div
              className={i18n.language === "ar" ? "arabic" : ""}
              key={item.id}
            >
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  {i18n.language === "ar" ? item.question_ar : item.question_en}
                </Accordion.Header>
                <Accordion.Body>
                  {i18n.language === "ar" ? item.answer_ar : item.answer_en}
                </Accordion.Body>
              </Accordion.Item>
            </div>
          );
        })}
      </Accordion>
    </>
  );
}
