import React from "react";
import Style from "./Footer.module.css";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const [t, i18n] = useTranslation();
  return (
    <>
      <footer
        className={`${Style.footer} ${
          i18n.language === "ar" ? "rtl-direction" : ""
        } pt-4 pb-3`}
      >
        <p className="text-white text-center px-5">
          {t("copy right")} {new Date().getFullYear() + ' ©'} {t("by")}  {""}
          <a
            href="https://gatetechs.com"
            target="_blank"
            rel="noopener noreferrer"
            className=" fw-semibold"
          >
            {t("gatetechs")}
          </a>{" "}
          {t("all rights reserved")}
        </p>
      </footer>
    </>
  );
}
