import React, { useEffect, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import Style from "./Services.module.css";
import { useTranslation } from "react-i18next";
import { api_Url } from "../../App";
import axios from "axios";

export default function Services() {
  const [t, i18n] = useTranslation();
  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api_Url}/frontend/services`);
        setItems(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section
        className={`${Style.Services} ${
          i18n.language === "ar" ? "rtl-direction" : ""
        } section-padding bg-section`}
        id="services"
      >
        <SectionTitle title={t("services")} />
        <div className="container">
          <div className="row g-4 mb-3">
            {items?.map((item) => {
              return (
                <div key={item.id} className="col-md-6 col-lg-4 col-xl-3">
                  <div className="item bg-white text-center px-4 py-2 rounded-3 shadow">
                    <div className="image py-3 d-flex justify-content-center align-items-center">
                      {/* <i className={`${item.icon} fa-2x`} /> */}
                      <img
                        src={item.image}
                        className="rounded-circle shadow-sm"
                        width="75px"
                        height="75px"
                        alt={item.title_en}
                      />
                    </div>
                    <h5 className="fw-bold pb-1">
                      {i18n.language === "ar" ? item.title_ar : item.title_en}
                    </h5>
                    <p className="paragraph">
                      {i18n.language === "ar"
                        ? item.description_ar
                        : item.description_en}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
