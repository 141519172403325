import React, { useEffect, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import Style from "./Contacts.module.css";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useFormik } from "formik";
import { Oval } from "react-loader-spinner";
import { api_Url } from "../../App";

export default function Contacts() {
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation();

  const validation = yup.object({
    name: yup
      .string()
      .required(i18n.language === "ar" ? "* الاسم مطلوب" : "* name is required")
      .max(
        40,
        i18n.language === "ar"
          ? "* يجب الا يزيد عن 40 حرف"
          : "max length is 40 chars"
      )
      .min(
        3,
        i18n.language === "ar"
          ? "* يجب الا يقل عن 3 حروف"
          : "min length is 3 chars"
      ),
    email: yup
      .string()
      .required(
        i18n.language === "ar"
          ? "* البريد الالكتروني مطلوب"
          : "* email is required"
      )
      .email(
        i18n.language === "ar"
          ? "* البريد الالكتروني غير صحيح"
          : "email is not valid"
      ),
    message: yup
      .string()
      .required(
        i18n.language === "ar" ? "* الرساله مطلوبة" : "* message is required"
      ),
  });

  async function sendData(values, { resetForm }) {
    console.log(values);
    setLoading(true);

    try {
      let { data } = await axios.post(`${api_Url}/frontend/contacts`, values, {
        headers: {
          "Content-Security-Policy": "upgrade-insecure-requests",
          "X-Requested-With": "XMLHttpRequest",
          Accept: "application/json",
        },
      });
      formik.setFieldValue("name", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("message", "");
      resetForm();
      setLoading(false);
      toast.success("Message Sent Successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => sendData(values, { resetForm }),
  });

  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api_Url}/frontend/settings`);
        setItems(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section
        className={`${Style.contact} ${
          i18n.language === "ar" ? "rtl-direction" : ""
        } section-padding`}
        id="contacts"
      >
        <SectionTitle title={t("contact us")} />
        <div className="container">
          <div className="row justify-content-center">
            {items?.map((item) => {
              return (
                <>
                  {item.key === "contact_address" && i18n.language === "en" ? (
                    <div className="col-lg-3">
                      <div>
                        <div className="item text-center px-4 py-2 rounded-3">
                          <div className="icon mb-3 rounded-circle d-flex justify-content-center align-items-center">
                            <i className="fa-solid fa-location-arrow fa-2x" />
                          </div>
                          <h5 className="fw-bold pb-1">{t("address")}</h5>
                          <p className="paragraph">
                            {item.key === "contact_address" &&
                            i18n.language === "en"
                              ? item.value
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.key === "contact_address_ar" &&
                  i18n.language === "ar" ? (
                    <div className="col-lg-3">
                      <div>
                        <div className="item text-center px-4 py-2 rounded-3">
                          <div className="icon mb-3 rounded-circle d-flex justify-content-center align-items-center">
                            <i className="fa-solid fa-location-arrow fa-2x" />
                          </div>
                          <h5 className="fw-bold pb-1">{t("address")}</h5>
                          <p className="paragraph">
                            {item.key === "contact_address_ar" &&
                            i18n.language === "ar"
                              ? item.value
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.key === "contact_email" ? (
                    <div className="col-lg-3">
                      <div>
                        <div className="item text-center px-4 py-2 rounded-3">
                          <div className="icon mb-3 rounded-circle d-flex justify-content-center align-items-center">
                            <i className="fa-solid fa-envelope fa-2x" />
                          </div>
                          <h5 className="fw-bold pb-1">{t("email")}</h5>
                          <p className="paragraph">
                            {item.key === "contact_email" ? item.value : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.key === "contact_phone" ? (
                    <div className="col-lg-3">
                      <div>
                        <div className="item text-center px-4 py-2 rounded-3">
                          <div className="icon mb-3 rounded-circle d-flex justify-content-center align-items-center">
                            <i className="fa-solid fa-phone fa-2x" />
                          </div>
                          <h5 className="fw-bold pb-1">{t("phone")}</h5>
                          <p className="paragraph">
                            {item.key === "contact_phone" ? item.value : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row justify-content-center g-4 mt-3">
              <div className="col-lg-5">
                <input
                  className="form-control border-0"
                  type="text"
                  placeholder={t("name")}
                  aria-label="default input example"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name ? (
                  <p
                    className={`fs-small ps-1 text-danger mb-0 ${
                      i18n.language === "ar" ? "text-end" : "text-start"
                    }`}
                  >
                    {formik.errors.name}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-5">
                <input
                  className="form-control border-0"
                  type="email"
                  value={formik.values.email}
                  placeholder={t("email")}
                  aria-label="default input example"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p
                    className={`fs-small ps-1 text-danger mb-0 ${
                      i18n.language === "ar" ? "text-end" : "text-start"
                    }`}
                  >
                    {formik.errors.email}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-10">
                <textarea
                  className="form-control border-0"
                  placeholder={t("message")}
                  value={formik.values.message}
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.message && formik.touched.message ? (
                  <p
                    className={`fs-small ps-1 text-danger mb-0 ${
                      i18n.language === "ar" ? "text-end" : "text-start"
                    }`}
                  >
                    {formik.errors.message}
                  </p>
                ) : (
                  ""
                )}
                <button
                  className="submit d-block mt-4 btn btn-info py-2 px-5 fw-semibold"
                  type="submit"
                >
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Oval
                        height={30}
                        width={30}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#86b7fe"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  ) : (
                    `${t("submit")}`
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
