import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Style from "./Sponsers.module.css";
import { api_Url } from "../../App";
import axios from "axios";

export default function Sponsers() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 4000,
    cssEase: "linear",
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api_Url}/frontend/testimonials`);
        setItems(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className={`${Style.mainContainer}`}>
        <Slider {...settings}>
          {items?.map((item) => (
            <div key={item.id} className="container">
              <img
                src={item.image}
                alt=""
                className="w-100"
                style={{ height: "125px" }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
