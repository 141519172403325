import React, { useEffect, useState } from "react";
import Style from "./About.module.css";
import img from "../../Assets/Images/aboutPayline.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { api_Url } from "../../App";
import axios from "axios";

export default function About() {
  const [t, i18n] = useTranslation();

  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api_Url}/frontend/settings`);
        setItems(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section
        className={`section-padding ${Style.about} ${
          i18n.language === "ar" ? "rtl-direction" : ""
        } `}
        id="about"
      >
        <div className="container py-4">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="image">
                <img
                  src={img}
                  className="w-100 ps-4 pb-4"
                  alt="PayLine Kuwait"
                />
              </div>
            </div>

            <div className="col-lg-7 p-xl-3 mt-5 mt-lg-0">
              <div>
                <h3 className="fw-bold">{t("about us")}</h3>
                {items?.map((item) => {
                  return (
                    <>
                      {item.key === "about_title_ar" ||
                      item.key === "about_title_en" ? (
                        <span className="sub-title text-uppercase d-inline-block mb-4">
                          {item.key === "about_title_ar" &&
                          i18n.language === "ar"
                            ? item.value
                            : item.key === "about_title_en" &&
                              i18n.language === "en"
                            ? item.value
                            : ""}
                        </span>
                      ) : null}

                      {item.key === "about_description_ar" &&
                      i18n.language === "ar" ? (
                        <p className="paragraph"> {item.value} </p>
                      ) : item.key === "about_description_en" &&
                        i18n.language === "en" ? (
                        <p className="paragraph"> {item.value} </p>
                      ) : null}
                    </>
                  );
                })}
                <div>
                  <Link
                    className="nav-link"
                    to="contacts"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={150}
                    aria-current="page"
                  >
                    <button className="btn btn-info py-2 px-3" href="#">
                      {t("contact us")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
